<template>
  <footer>
    <div class="footer p--m" :class="{ 'white': route.path == '/style' || route.path == '/room', 'blue': route.path == '/result' }" v-if="pageData" ref="footerRef">
      <div class="footer__col">
        <span class="footer__copyright roboto--300">© {{ pageData.content.start_footer_copyright }} {{ new Date().getFullYear() }}</span>
      </div>
      <div class="footer__col">
        <div class="footer__nav roboto--300" v-if="navItems">
          <a v-for="item in navItems" :href="item.nav_url" target="_blank">{{ item.nav_title }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {doQuery, getPageQuery, getStartQuery} from "@/utils/queries";
import {ref, onMounted, onUpdated} from "vue";
import yaml from "js-yaml";

const route = useRoute();

import {useGeneralStore} from "@/stores/general";
import { useRoute } from "vue-router";
const generalStore = useGeneralStore();

import {usePagesStore} from "@/stores/pages";
const pagesStore = usePagesStore();

const footerRef = ref(null);
const pageData = ref(null);
const navItems = ref([]);

onMounted(async () => {
  if(pagesStore.pagesData && pagesStore.pagesData['start']) {
    pageData.value = pagesStore.pagesData['start'];
  } else {
    console.log('LOAD THE DATA IN FOOTER');
    const pageStartQuery = getStartQuery("Start");
    pageData.value = await doQuery(pageStartQuery);
    pagesStore.pagesData['start'] = pageData.value;
  }

  if(pageData.value) {
    navItems.value = yaml.load(pageData.value.content.start_footer_nav);
  }
});

onUpdated(() => {
  if (footerRef.value) generalStore.footerHeight = footerRef.value.clientHeight;
});
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid var(--vb_grey_mid);
  background-color: var(--vb_grey_light);
  width: 100%;

  .footer__col {
    .footer__copyright {
      color: var(--vb_grey_dark);
    }
    .footer__nav {
      display: flex;
      gap: 16px;
      a {
        color: var(--vb_grey_dark);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.white {
    background-color: var(--vb_white);
  }

  &.blue {
    background-color: var(--vb_blue_light);
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    .footer__col {
      margin: 0 0 8px 0;
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}
</style>