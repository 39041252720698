<template>
  <header>
    <div class="header" v-show="routeName && routeName !== 'start' && routeName !== 'register'">
      <!-- <nav class="debugger">
        <RouterLink to="/">01 Start</RouterLink>
        <RouterLink to="/style">02 Stil</RouterLink>
        <RouterLink to="/look">03 Look</RouterLink>
        <RouterLink to="/room">04 Raum</RouterLink>
        <RouterLink to="/result">05 Ergebnis</RouterLink>
        <RouterLink to="/register">06 Registrierung</RouterLink>
        <RouterLink to="/debug">07 Debugger</RouterLink>
      </nav> -->

      <!-- <div v-if="routeName && routeName === 'start'" class="nav nav--start"></div> -->

      <div class="nav nav--default">
        <div class="nav__toggler" ref="mobileToggler" v-if="isMobile" @click="openMobileSelect">
          <div class="nav__back" :data-finished="!routeCheck()" @click="goHistoryBack($event)">
            <SVG v-if="routeCheck()" path="icons" svg="icon_arrow--left" max="14"></SVG>
            <SVG v-else path="icons" svg="icon_checkmark"></SVG>
          </div>
          <div class="nav__text h6 roboto--400" v-if="routeCheck()">Schritt:</div>
          <div class="nav__text h6 roboto--300" v-if="routeCheck()">{{ currentRouteIndex(route.name.toLowerCase()) + 1 }} / {{ routesStore.navRoutes.length - 1 }}</div>
          <div class="nav__text h6 roboto--300" v-else>Erfolgreich abgeschlossen</div>
          <div class="nav__arrow">
            <SVG path="icons" svg="icon_chevron--down_nogap" max="18"></SVG>
          </div>
        </div>
        <div class="nav__wrapper" ref="mobileSelect">
          <div class="nav__container">
            <div class="nav__item" v-for="(item,index) in routesStore.navRoutes"
                 :data-allowed="isAnAllowedRoute(item.name)" :data-finished="isAFinishedRoute(item.name)">
              <div class="nav__inner">
                <div class="nav__indicator" v-if="!isMobile">
                  <div v-if="isAFinishedRoute(item.name)" class="nav__color nav__color--done p--s"></div>
                  <div v-else-if="isAnAllowedRoute(item.name)" class="nav__color nav__color--current"></div>
                  <div v-else class="nav__color nav__color--inactive"></div>
                </div>
                <div class="nav__step">
                  <RouterLink v-if="isAFinishedRoute(item.name)" class="nav__link nav__link--done p--m roboto--400" :to="item.path">{{ item.title }}</RouterLink>
                  <RouterLink v-else-if="isAnAllowedRoute(item.name)" class="nav__link nav__link--current p--m roboto--300" :to="item.path">{{ item.title }}</RouterLink>
                  <span v-else class="nav__link nav__link--disabled p--m roboto--300">{{ item.title }}</span>
                </div>
                <SVG path="icons" svg="icon_arrow--right--thin" max="24" v-if="index !== routesStore.navRoutes.length - 1 && !isMobile" class="nav__arrow"></SVG>
                <div class="nav__data">
                  <div v-if="pageNames[item.name]" class="p--m roboto--300">{{ pageNames[item.name] }}</div>
                  <div v-else-if="!pageNames[item.name] && isAFinishedRoute(item.name)">
                    <!-- <l-ring size="10" stroke="1" bg-opacity="0" speed="2" color="rgba(51, 51, 51, 0.48)"></l-ring> -->
                  </div>
                  <div v-else></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header__logo">
        <SVG path="logos" svg="logo" max="155"></SVG>
      </div>

    </div>

    <!-- <div v-if="routeName && routeName === 'register'" class="nav nav--register"></div> -->
  </header>
</template>

<script setup lang="ts">
import {ref, onMounted, watch, computed, onBeforeMount} from 'vue';
import {RouterLink, RouterView, useRoute, useRouter} from 'vue-router';
import {setAllowedRoutes, isAFinishedRoute, isAnAllowedRoute, currentRouteIndex} from "@/utils/routes";

import {doQuery, getHotspotsForLookQuery, getPageQuery, getStartQuery} from "@/utils/queries";

const routeName = ref('');
const route = useRoute();
const router = useRouter();

// const router = useRouter();
// const allRoutes = router.options.routes;
//
// const allowedRoutes = ref([]);

import {useConfigurationStore} from "@/stores/configuration";

const configurationStore = useConfigurationStore();

import {useRoutesStore} from "@/stores/routes";

const routesStore = useRoutesStore();

import {usePagesStore} from "@/stores/pages";

const pagesStore = usePagesStore();

import SVG from "@/components/SVG.vue";

import 'ldrs/ring';
import {useIsMobile} from "@/composables/useIsMobile";
import {datalayerPush} from "@/utils/tracking";

const pageNames = ref({});

const mobileSelect = ref(null);
const mobileToggler = ref(null);

const {isMobile} = useIsMobile();

// const allowedRoutes = ref([]);
// const finishedRoutes = ref([]);

// onBeforeMount(() => {
//   routeName.value = route.name;
//   setAllowedRoutes();
//   fetchPageName();
// });

const goHistoryBack = (event) => {
  event.stopPropagation();
  router.go(-1);
}

const openMobileSelect = () => {
  if(isMobile) {
    // console.log(mobileSelect.value.style.height);
    mobileSelect.value.style.height = mobileSelect.value.style.height === "0px" || mobileSelect.value.style.height === 0 || !mobileSelect.value.style.height ? mobileSelect.value.scrollHeight + 'px' : "0px";
    mobileSelect.value.classList.toggle('nav__wrapper--open');
    mobileToggler.value.classList.toggle('nav__toggler--active');
  }
}

const fetchPageName = () => {
  // console.log('fetchPageName', routesStore.finishedRoutes);
  routesStore.finishedRoutes.forEach(async (route) => {
    const key = Object.keys(route)[0];


    // const pageRequest = getPageQuery(route[key]);
    // const pageData = await doQuery(pageRequest);

    let pageData = null;
    if (pagesStore.pagesData && pagesStore.pagesData[route[key]]) {
      pageData = pagesStore.pagesData[route[key]];
    } else {
      console.log('LOAD THE DATA IN HEADER: ' + route[key]);
      const pageRequest = getPageQuery(route[key]);
      pageData = await doQuery(pageRequest);
      pagesStore.pagesData[route[key]] = pageData;
    }



    // console.log('pageData', pageData);
    if (pageData) pageNames.value[key] = pageData.title ?? '';
  });
  // const item = configurationStore.bathroomConfig.filter(item => item.hasOwnProperty(key));
  // if(item.length > 0) {
  //   const pageRequest = getPageQuery(item[0][key]);
  //   const pageData = await doQuery(pageRequest);
  //   pageNames.value[key] = pageData.title ?? '';
  // }
}

const routeCheck = () => {
  // return (currentRouteIndex(route.name.toLowerCase()) + 1) <= (routesStore.navRoutes.length - 1);
  return (currentRouteIndex(routeName.value.toLowerCase()) + 1) <= (routesStore.navRoutes.length - 1);
}

const checkIfRouteIsAllowed = () => {
  if(!isAnAllowedRoute(routeName.value)) {
    router.push('/');
  }
}

onMounted(async () => {
  // console.log('ROUTENAME', router.currentRoute.value);
  // if(router.currentRoute.value) {
  //   datalayerPush("", `/applic/baddesigner/virtual${router.currentRoute.value.path}`, "", "", "virtualpageview");
  // }
  // if(document.referrer) {
  //   datalayerPush("started", router.currentRoute.value.name);
  // }
  await router.isReady();
  routeName.value = router.currentRoute.value.name;
  setAllowedRoutes();
  checkIfRouteIsAllowed();
  fetchPageName();
});

watch(routesStore.finishedRoutes, () => {
  setAllowedRoutes();
  fetchPageName();
});

watch(isMobile, (newValue) => {
  if (!isMobile.value) {
    mobileSelect.value.style.height = "auto";
    mobileSelect.value.classList.remove('nav__wrapper--open');
    mobileToggler.value.classList.remove('nav__toggler--active');
  } else {
    mobileSelect.value.style.height = "0px";
  }
});

// watch(route, (newRoute) => {
//   console.log('newRoute',newRoute);
//   routeName.value = newRoute.name;
//   checkIfRouteIsAllowed();
// });
</script>

<style scoped lang="scss">
.debugger {
  position: fixed;
  top: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);

  > a {
    text-align: center;
  }
}

.header {
  display: flex;
  gap: 48px;
  justify-content: space-between;
  max-width: 100vw;
  padding: 0 60px;
  // margin: 0 0 56px 0;
  margin: 0 0 54px 0;
}

.nav {
  border-bottom: 1px solid var(--vb_grey_mid);

  &.nav--default {
    max-width: calc(100vw - 120px);
    width: 100vw;
    padding: 32px 0;

    .nav__wrapper {
      .nav__container {
        display: flex;
        gap: 24px;
        align-items: flex-start;

        .nav__item {
          display: flex;
          align-items: center;
          gap: 24px;
          color: var(--vb_grey_dark);

          .nav__inner {
            display: grid;
            gap: 0 8px;
            place-items: center start;
            grid-template-columns: auto auto auto;
            grid-template-rows: auto auto;
            transition: 300ms;

            .nav__indicator {
              .nav__color {
                width: 8px;
                height: 8px;
                border-radius: 100%;

                &.nav__color--done {
                  background-color: var(--vb_succes_light);
                }

                &.nav__color--current {
                  background-color: var(--vb_blue_pressed);
                }

                &.nav__color--inactive {
                  background-color: var(--vb_grey_dark);
                }
              }
            }

            .nav__arrow {
              margin: 0 0 0 16px;
            }

            .nav__step {
              display: flex;
              align-items: center;
              gap: 8px;

              .nav__link {
                text-decoration: none;
                transition: color 350ms ease;

                &--done {
                  color: var(--vb_black);
                  cursor: pointer;
                  &:hover {
                    color: var(--vb_blue_hover);
                  }
                }

                &--current {
                  color: var(--vb_blue_pressed);
                  cursor: pointer;
                }

                &--disabled {
                  color: var(--vb_undefined);
                }
              }
            }

            .nav__data {
              grid-column: 2 / 3;
              grid-row: 2 / 3;
              color: var(--vb_undefined);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    gap: 16px;
    padding: 0 24px;
    margin: 0 0 32px 0;
  }
  .nav {
    border: none;
    &.nav--default {
      display: grid;
      grid-template-columns: 32px auto;
      grid-template-rows: min-content;
      width: auto;
      position: relative;
      // padding: 16px 0;
      padding: 0;
      margin: 16px 0;

      .nav__toggler {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 2px 16px 2px 2px;
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
        background-color: var(--vb_grey_light);
        transition: border-radius 350ms ease;
        .nav__back {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 100%;
          background-color: var(--vb_grey_mid);
          &[data-finished="true"] {
            background-color: var(--vb_succes_light);
          }
        }
        .nav__arrow {
          transform: rotate(180deg);
          transition: transform 350ms ease;
        }
        &.nav__toggler--active {
          border-bottom-right-radius: 0px;
          .nav__arrow {
            transform: rotate(0deg);
          }
        }
      }

      .nav__wrapper {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        position: absolute;
        top: 0;
        width: 100%;
        height: 0;
        z-index: 3;
        overflow: hidden;
        // background-color: var(--vb_white);
        background-color: var(--vb_grey_light);
        transition: height 350ms ease;
        .nav__container {
          display: flex;
          gap: 0;
          flex-direction: column;

          .nav__item {
            gap: 0;
            width: 100%;
            min-height: 48px;
            padding: 16px;

            .nav__inner {
              grid-template-columns: 1fr;
              .nav__data {
                grid-column: 1 / 2;
              }
            }

            &[data-allowed="true"] {
              background-color: var(--vb_blue_light);
            }

            &[data-finished="true"] {
              background-color: var(--vb_succes_light);
            }
          }
        }
        &.nav__wrapper--open {}
      }
    }
  }
  .header__logo {
    max-width: 108px;
  }
}
</style>