<template>
  <transition name="fade" mode="out-in" class="ts">
    <div :key="route.fullPath">
      <Header/>
      <div class="content__container">
        <main class="main">
          <RouterView/>
        </main>
      </div>
      <Footer/>
    </div>
  </transition>
</template>

<script setup lang="ts">
import {RouterView, useRoute, useRouter} from 'vue-router';
import { onMounted, watch, ref } from 'vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

const route = useRoute();
const router = useRouter();
const consentURL = import.meta.env.VITE_CONSENT_URL || '';
import {setBathroomConfiguration} from './utils/configuration';
import {datalayerPush} from "@/utils/tracking";

import {useRoutesStore} from "@/stores/routes";
const routesStore = useRoutesStore();

const toolHasStarted = ref(false);

onMounted(async () => {
  console.error("App.vue");

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  if (code) window.localStorage.setItem('bath-code', code);

  setBathroomConfiguration();

  const script = document.createElement('script');
  script.src = consentURL;
  script.id = "vb-consent-manager";
  script.setAttribute('data-language-code', 'de');
  document.body.appendChild(script);
});

watch(route, (to, from) => {
  if (document.referrer && to.fullPath !== '/' && !routesStore.toolHasStarted) {
    // !toolHasStarted.value) {
    datalayerPush("started", router.currentRoute.value.name );
    routesStore.toolHasStarted = true;
  }
  // datalayerPush("", `/applic/baddesigner/virtual${router.currentRoute.value.path}`, "", "", "virtualpageview");
  datalayerPush("", `/applic/baddesigner/virtual${to.fullPath}`, "", "", "virtualpageview");
})
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.content__container {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
</style>